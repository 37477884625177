
import { Component, Vue, Prop } from 'vue-property-decorator'
import DownZip from 'downzip'
import API from '@/api/index'
import FormatBytesAsset from '@/utils/formatBytesAsset'
import SnakeToCamelCase from '@/utils/snakeToCamelCase'

interface AssetInterface {
    id: number
    name: string
    fileSize: number
    size: string
    path: string
    previewPath: string
    type: string
    hdDownloadUrl: string
    lowResDownloadUrl: string
}

@Component
export default class Assets extends Vue {
    @Prop() readonly rawUrl?: string
    @Prop() readonly proxyUrl?: string
    @Prop() readonly isRawDownloadReady?: boolean
    @Prop() readonly isProxyDownloadReady?: boolean

    projectId: number = -1
    activeFilter: string = ''
    assets: Array<AssetInterface> = []
    displayElementIndex: number = -1
    loading = true

    get activeAssets() {
        return this.activeFilter ? this.assets.filter(asset => asset.type === this.activeFilter)
            : this.assets
    }

    filterAssets(type: string) {
        this.activeFilter = type
        this.displayElementIndex = -1
    }

    toggleOptions(index: number) {
        this.displayElementIndex = this.displayElementIndex === index ? -1 : index
    }

    extractFileName(assetUrl: string, fallback: string): string {
        const url = new URL(assetUrl)
        const name = url.pathname.split('/').pop()
        return name || fallback
    }

    async triggerDownload(kind: 'proxy' | 'raw') {
        const downloads = this.assets.map(({
            name, fileSize, hdDownloadUrl, lowResDownloadUrl,
        }) => ({
            name: kind === 'proxy' ? this.extractFileName(lowResDownloadUrl, name) : name,
            size: fileSize,
            downloadUrl: kind === 'proxy' ? lowResDownloadUrl : hdDownloadUrl,
        }))

        const mapScriptUrl = () => `${process.env.BASE_URL}downzip-sw.js`
        const downzip = new DownZip()
        await downzip.register({ mapScriptUrl })
        const downloadID = `${this.projectId}-${kind}`
        const zipFileName = `project-${this.projectId}_${kind}_assets`

        const downloadUrl = await downzip.downzip(
            downloadID,
            zipFileName,
            downloads,
        )

        const a = document.createElement('a')
        a.href = downloadUrl;
        (document.body || document.documentElement).appendChild(a)
        a.click()
        if (a.parentNode) {
            a.parentNode.removeChild(a)
        }
    }

    triggerRawDownload() {
        this.triggerDownload('raw')
    }

    triggerProxyDownload() {
        this.triggerDownload('proxy')
    }

    async getAssets() {
        const response = await API.GetProjectAssets(this.projectId)
        const assetsList = response.data

        Promise.all(assetsList.map(async (elem) => {
            const lastPointOcurrence = elem.name.lastIndexOf('.')
            const hdUrl = await API.GetAssetDownloadUrl({
                name: elem.name,
                size: elem.size || 0,
                type: SnakeToCamelCase(elem.type.name),
                uploadType: '',
                operationName: 'download',
            }, this.projectId)
            const lowResUrl = await API.GetAssetDownloadUrl({
                name: `${elem.name.substring(0, lastPointOcurrence)}_proxy.mp4`,
                size: elem.size || 0,
                type: SnakeToCamelCase(elem.type.name),
                uploadType: 'deliverable',
                operationName: 'download',
            }, this.projectId)
            this.assets.push({
                id: elem.id,
                name: elem.name,
                fileSize: elem.size,
                size: FormatBytesAsset(elem.size),
                path: elem.path,
                previewPath: elem.previewPath,
                type: elem.type.name,
                hdDownloadUrl: hdUrl.data,
                lowResDownloadUrl: elem.type.name === 'ASSET' ? hdUrl.data : lowResUrl.data,
            })
        })).then(() => this.loading = false)
    }

    mounted() {
        this.projectId = Number(this.$route.params.projectId)
        this.getAssets()
        this.activeFilter = 'INTERVIEW_CLIP'
    }
}

